@keyframes starSpin {
    0% {

        transform: rotate(0deg) scale(100%);
    }
    50% {
        transform: rotate(180deg) scale(130%);
    }
    100% {

        transform: rotate(360deg) scale(100%);
    }
}


.spinningStars {
    animation-name: starSpin;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.pause {
    animation: none;
}

.run {
}

.BaseLoginPage {

    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    min-height: 100vh;
    height: 100vh;
    min-height: -webkit-fill-available;

    background-image: url(../assets/images/login_background_white.png);
    background-size: cover;
}

.BaseLoginPage .MuiInputBase-root {
    background-color: rgba(255, 255, 255, 0.75);
}
